import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import history from 'util/router-history';
import { ERROR_TYPES } from 'util/errors';
import { getStepFromURL } from 'util/url';
import { getMemberSince } from 'util/data-gathering-helper';
import ROUTES from 'constants/routes';
import { PartnerContext } from 'contexts/PartnerProvider';
import { AppContext } from 'contexts/AppProvider';
import { searchPhoneNumDebounced } from 'api/venue';
import { addAmount as addAmountPOST } from 'api/add';
import { authPartner as authPartnerPOST } from 'api/partner';
import { addNoteToTransaction as addNoteToTransactionPOST } from 'api/notes';

export const STEPS = {
  MOBILE_NUMBER: 'MOBILE_NUMBER',
  VALIDATE_PASSWORD: 'VALIDATE_PASSWORD',
  ADD_AMOUNT: 'ADD_AMOUNT',
  ADD_SUCCESS: 'ADD_SUCCESS',
};

const ACTIONS = {
  RESET: 'RESET',
  SET_PHONE: 'SET_PHONE',
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_PHONE_REMOVE_CUSTOMER: 'SET_PHONE_REMOVE_CUSTOMER',
  SET_AMOUNT: 'SET_AMOUNT',
  GOTO_STEP: 'GOTO_STEP',
  SET_AUTH_STATUS: 'SET_AUTH_STATUS',
  IS_PHONE_LOADING: 'IS_PHONE_LOADING',
  IS_LOADING: 'IS_LOADING',
};

const initialState = {
  step: STEPS.MOBILE_NUMBER,
  customer: {
    id: 0,
    loyaltyAccountId: 0,
    isFound: false,
    resendRequested: false,
    phone: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthday: null,
    email: '',
    balance: 0,
    totalClaimed: 0,
    totalRedeemed: 0,
    amountAdded: 0,
    lastTransactionId: 0,
    memberSince: '',
    currentTier: {},
  },
  isAuthorized: true,
  nextRequestId: 1,
  displayedRequestId: 0,
  isPhoneLoading: {},
  idempotencyKey: '',
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.RESET:
      return initialState;
    case ACTIONS.SET_PHONE: {
      const prevState = { ...state };
      const customer = { ...prevState.customer, phone: action.payload };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_CUSTOMER: {
      const prevState = { ...state };
      const customer = {
        ...prevState.customer,
        isFound: true,
        ...action.payload,
      };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_PHONE_REMOVE_CUSTOMER: {
      const prevState = { ...state };
      const customer = { ...initialState.customer, phone: action.payload };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_AMOUNT: {
      const prevState = { ...state };
      const customer = { ...prevState.customer, ...action.payload };
      return { ...prevState, customer };
    }
    case ACTIONS.GOTO_STEP: {
      const prevState = { ...state };
      const { STEP: step, ...rest } = action.payload;
      return { ...prevState, step, ...rest };
    }
    case ACTIONS.SET_AUTH_STATUS: {
      const prevState = { ...state };
      const isAuthorized = action.payload;
      return { ...prevState, isAuthorized };
    }
    case ACTIONS.IS_PHONE_LOADING: {
      const prevState = { ...state };
      const { isPhoneLoading } = action.payload;
      return {
        ...prevState,
        isPhoneLoading: { ...prevState.isPhoneLoading, ...isPhoneLoading },
      };
    }
    case ACTIONS.IS_LOADING: {
      const prevState = { ...state };
      return {
        ...prevState,
        isLoading: action.payload,
      };
    }
    default:
      throw new Error('Invalid action dispatched');
  }
};

/* Entire data control for add steps */
const useWrapper = () => {
  const {
    partner: {
      username,
      token,
      currentVenueId,
      currencyDenomination,
      phonePrefix,
      venueSettings = {},
    },
    partner,
    updateToken,
  } = React.useContext(PartnerContext);

  const {
    setAppState,
    app: { shouldReloadAppOnPageIdle, globalCustomer = {} },
  } = React.useContext(AppContext);

  const initialStateWithOverride = { ...initialState };
  initialStateWithOverride.customer = {
    ...initialState.customer,
    ...globalCustomer,
  };

  // get current step
  if (globalCustomer.phone && globalCustomer.phone.length >= 3) {
    const initialStep = getStepFromURL();
    initialStateWithOverride.step = initialStep || STEPS.MOBILE_NUMBER;
  }

  const [state, dispatch] = React.useReducer(reducer, initialStateWithOverride);
  const { step, customer, isAuthorized, isPhoneLoading, idempotencyKey, isLoading } = state;

  const locale = {
    currencyDenomination,
    phonePrefix,
  };

  const resetDataHandler = () => {
    if (shouldReloadAppOnPageIdle) {
      window.location.reload(true);
    } else {
      setAppState({ isAppIdle: true, globalCustomer: {} });
      dispatch({ type: ACTIONS.RESET });
    }
  };

  const redirectToDefaultPageHandler = () => {
    setAppState({ globalCustomer: {} });
    history.push(ROUTES.NAV);
  };

  const phoneNumberSearchHandler = async (phoneNum) => {
    try {
      // To avoid race condition: we here are adding a count for each api hit
      // and saving it to local scope (requestId), so after api response we check with the local count
      initialState.nextRequestId += 1;
      const requestId = initialState.nextRequestId;

      dispatch({
        type: ACTIONS.IS_PHONE_LOADING,
        payload: {
          isPhoneLoading: { [phoneNum]: true },
        },
      });

      const data = await searchPhoneNumDebounced({
        token,
        currentVenueId,
        phone: phoneNum,
      });

      dispatch({
        type: ACTIONS.IS_PHONE_LOADING,
        payload: {
          isPhoneLoading: { [phoneNum]: false },
        },
      });

      // To avoid race condition: we here are returing (no action taken)
      // if the api requested is older then the global requestid we have in (displayedRequestId)
      // else we change the global requestId to the current requestId.
      if (requestId < initialState.displayedRequestId) return;
      initialState.displayedRequestId = requestId;

      const {
        id,
        firstName,
        lastName = '',
        phone,
        email,
        gender,
        birthday,
        loyaltyAccount: {
          id: loyaltyAccountId,
          balanceAmount,
          cashbackAmount,
          redeemedAmount,
          createdAt,
        } = {},
        currentTier = {},
      } = data;

      if ((firstName || lastName) && phone) {
        dispatch({
          type: ACTIONS.SET_CUSTOMER,
          payload: {
            id,
            loyaltyAccountId,
            firstName,
            lastName: lastName || '',
            phone,
            email,
            gender,
            birthday: birthday || '',
            balance: balanceAmount,
            totalClaimed: cashbackAmount,
            totalDeducted: redeemedAmount,
            memberSince: getMemberSince(createdAt),
            currentTier,
          },
        });

        setAppState({
          globalCustomer: {
            id,
            loyaltyAccountId,
            firstName,
            lastName: lastName || '',
            phone,
            email,
            gender,
            birthday: birthday || '',
            balance: balanceAmount,
            totalClaimed: cashbackAmount,
            totalDeducted: redeemedAmount,
            memberSince: getMemberSince(createdAt),
            currentTier,
          },
        });
      } else {
        // IF NOT FOUND - goto ADD_CUSTOMER step
        dispatch({
          type: ACTIONS.SET_PHONE_REMOVE_CUSTOMER,
          payload: phoneNum,
        });
      }
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else {
        // error scenario
        console.error('Something blew up while searching for phone number', e);
        dispatch({
          type: ACTIONS.IS_PHONE_LOADING,
          payload: {
            isPhoneLoading: { [phoneNum]: false },
          },
        });
      }
    }
  };

  const gotoNextStepFromPhoneNumStep = () => {
    setAppState({ isAppIdle: false });
    const { isFound } = customer;
    if (isFound) {
      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.ADD_AMOUNT, idempotencyKey: uuidv4() },
      });
    } else {
      console.error('Debug why next step was enabled when guest was not found');
    }
  };

  const addAmountHandler = async (amount) => {
    dispatch({
      type: ACTIONS.SET_AMOUNT,
      payload: { amountAdded: amount },
    });

    dispatch({
      type: ACTIONS.GOTO_STEP,
      payload: { STEP: STEPS.VALIDATE_PASSWORD },
    });
  };

  const submitPasswordHandler = async (password) => {
    try {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: true,
      });

      const data = await authPartnerPOST({ username, password });
      const { accessToken: tokenFromRes } = data;

      const amountToAdd = customer.amountAdded || 0;

      const additionData = await addAmountPOST({
        token: tokenFromRes,
        currentVenueId,
        loyaltyAccountId: customer.loyaltyAccountId,
        amount: amountToAdd,
        customerId: customer.id,
        idempotencyKey,
      });
      const {
        id: lastTransactionId,
        amount: amountAdded,
        guest: { loyaltyAccount: { id: loyaltyAccountId, balanceAmount: balance } = {} } = {},
      } = additionData;

      // update token in cookies
      updateToken(tokenFromRes);

      // deeduct success
      dispatch({ type: ACTIONS.SET_AUTH_STATUS, payload: true });
      dispatch({
        type: ACTIONS.SET_AMOUNT,
        payload: { lastTransactionId, balance, amountAdded, loyaltyAccountId },
      });
      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.ADD_SUCCESS },
      });
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else {
        dispatch({ type: ACTIONS.SET_AUTH_STATUS, payload: false });
        // error scenario
        console.error('Something blew up while validating password', e);
      }
    } finally {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: false,
      });
    }
  };

  const addNoteToTransactionHandler = (note) => {
    try {
      const { lastTransactionId } = customer;

      addNoteToTransactionPOST({
        token,
        currentVenueId,
        note,
        transactionId: lastTransactionId,
      });
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else {
        // error scenario
        console.error('Something blew up while adding notes', e);
      }
    }
  };

  const addSuccessRedirectHandler = () => {
    redirectToDefaultPageHandler();
  };

  return {
    step,
    partner,
    customer,
    locale,
    isAuthorized,
    isLoading,
    isPhoneLoading,
    venueSettings,
    resetDataHandler,
    redirectToDefaultPageHandler,
    phoneNumberSearchHandler,
    gotoNextStepFromPhoneNumStep,
    addAmountHandler,
    submitPasswordHandler,
    addSuccessRedirectHandler,
    addNoteToTransactionHandler,
  };
};

export default useWrapper;
